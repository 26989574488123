import { Content, Flex } from 'components/box'
import Button from 'components/button'
import Input from 'components/input'
import Layout from 'components/layout'
import PropTypes from 'prop-types'
import React from 'react'
import { Heading, Lead } from 'components/text'

const Contact = props => {
  const formProps = {
    action: '/sent',
    name: 'contact',
    method: 'POST',
    'data-netlify': true,
    'data-netlify-honeypot': 'bot-field',
  }

  return (
    <Layout {...props} pageTitle="Contact us">
      <Content
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        pb={6}
        pt={5}
      >
        <Heading level={1} textAlign="center">
          Contact us
        </Heading>
        <Lead
          color="neutral-500"
          fontWeight="medium"
          textAlign="center"
          measure="normal"
          mb={4}
          mt={1}
          mx="auto"
        >
          We love e-mails! If there's anything you'd like us to know, feel free
          to reach out!
        </Lead>
        <Flex
          as="form"
          flexDirection="column"
          width="100%"
          maxWidth="normal"
          {...formProps}
        >
          <input type="hidden" name="form-name" value="contact" />
          <Flex flexDirection="column" my={3}>
            <Heading as="label" color="neutral-400" htmlFor="name" level={6}>
              Name
            </Heading>
            <Input
              type="text"
              id="name"
              name="name"
              size="large"
              mt={2}
              required
            />
          </Flex>
          <Flex flexDirection="column" my={3}>
            <Heading as="label" color="neutral-400" htmlFor="email" level={6}>
              E-mail
            </Heading>
            <Input
              type="email"
              id="email"
              name="email"
              size="large"
              mt={2}
              required
            />
          </Flex>
          <Flex flexDirection="column" my={3}>
            <Heading as="label" color="neutral-400" htmlFor="message" level={6}>
              Message
            </Heading>
            <Input
              as="textarea"
              type="text"
              id="message"
              name="message"
              size="large"
              minHeight="8rem"
              style={{ resize: 'vertical' }}
              mt={2}
              required
            />
          </Flex>
          <Button type="submit" mt={3} size="large">
            Send
          </Button>
        </Flex>
      </Content>
    </Layout>
  )
}

Contact.propTypes = {
  location: PropTypes.object,
}

export default Contact
